import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [



  {
    pid: 2,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'iBG',
    lpAddresses: {
      97: '',
      56: '0x8673491B4B72695D4BE789fe814D1dd0988e19A8', // EGG-BUSD LP
    },
    tokenSymbol: 'iBG',
    tokenAddresses: {
      97: '',
      56: '0x5c46c55A699A6359E451B2c99344138420c87261',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },




  {
    pid: 0,
    risk: 5,
    lpSymbol: 'iBG-BNB',
    lpAddresses: {
      97: '',
      56: "0x8673491B4B72695D4BE789fe814D1dd0988e19A8",
    },
    tokenSymbol: 'iBG',
    tokenAddresses: {
      97: '',
      56: '0x5c46c55a699a6359e451b2c99344138420c87261',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },



  {
    pid: 1,
    risk: 5,
    lpSymbol: 'iBG-BUSD',
    lpAddresses: {
      97: '',
      56: '0x36B8eE5c1f8FFB3b224736Ca9c989B1c531a613d',
    },
    tokenSymbol: 'iBG',
    tokenAddresses: {
      97: '',
      56: '0x5c46c55a699a6359e451b2c99344138420c87261',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  ///------------------------------------




  {
    pid: 3,
    risk: 1,
    lpSymbol: 'BUSD-BNB',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

]

export default farms
